/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'add-to-clan': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<path pid="0" d="M22.6 1.57h.001c1.762.698 2.86 2.047 3.524 3.25.33.601.549 1.157.683 1.562a7.628 7.628 0 01.174.602l.007.03.002.006v0h0v.001l.001.007.002.007c.557 2.363.726 4.791.698 6.973h0v.013c.028 2.147-.169 4.578-.727 6.974h0l-.003.013v0h0v0h0l-.002.007-.006.03-.032.125c-.029.111-.075.275-.142.477a9.456 9.456 0 01-.684 1.561c-.663 1.204-1.762 2.553-3.524 3.251l-.003.002c-1.695.687-4.879 1.01-8.092 1.039-3.207-.03-6.397-.38-8.098-1.041-1.761-.7-2.859-2.047-3.522-3.251a9.47 9.47 0 01-.684-1.561 7.66 7.66 0 01-.174-.602l-.007-.03-.001-.006h0v-.001h0l-.002-.009-.002-.009c-.585-2.39-.754-4.817-.726-6.998h0v-.013c-.028-2.148.169-4.578.727-6.975h0l.003-.012h0l.001-.007.007-.03.031-.125c.03-.112.076-.275.143-.477a9.47 9.47 0 01.684-1.561c.663-1.204 1.761-2.552 3.522-3.25C8.08.878 11.27.528 14.477.5c3.207.03 6.396.38 8.123 1.07z" _stroke="#6D6D75"/><path pid="1" d="M17.697 8.36a.352.352 0 00-.135 0l-4.007.782v5.242c0 1.608.796 3.084 2.078 3.918L17.63 19.6l1.997-1.3c1.281-.833 2.077-2.31 2.077-3.916V9.141l-4.007-.782zm-.191 11.321zm.247 0zm-.506-12.904c.253-.05.512-.05.765 0l4.664.91a.809.809 0 01.657.792v5.905c0 2.127-1.052 4.12-2.811 5.266L18.4 21.03a1.412 1.412 0 01-1.543 0l-2.12-1.38c-1.76-1.145-2.812-3.139-2.812-5.265V8.479c0-.385.275-.717.657-.791l4.664-.91zM7.778 10.889c1.14 0 1.037 1.037 1.037 2.074 1.037 0 2.074-.11 2.074 1.037a1.037 1.037 0 01-1.037 1.037H8.815c0 1.037.104 2.074-1.037 2.074-1.14 0-1.037-1.037-1.037-2.074-1.037 0-2.074.103-2.074-1.037 0-1.141 1.037-1.037 2.074-1.037 0-1.037-.104-2.074 1.037-2.074z" _fill="#898B9B"/>'
  }
})
